import { Global as EmotionGlobal } from "@emotion/react";
import React from "react";

const Global: React.FC = () => {
  return (
    // reset styles
    <EmotionGlobal
      styles={(_theme) => ({
        "*": {
          margin: 0,
          padding: 0,
        },
        "ul": {
          listStyle: "none",
        },
        "table": {
          borderSpacing: 0,
          borderCollapse: "collapse",
        },
      })}
    />
  );
};

export default Global;
