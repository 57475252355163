import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

// implementation mostly taken from https://www.gatsbyjs.com/docs/add-seo-component/

// TODO: do we need to add "prefix" component to head?
//   <head prefix=”og: http://ogp.me/ns#”>
//   <head prefix="og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# website: http://ogp.me/ns/website#">

type SeoProps = {
  title?: string | null;
  siteTitleTemplate?: string | null;
  description?: string | null;
  image?: string | null;
  article?: boolean;
  isShishimai?: boolean;
};

const Seo: React.FC<SeoProps> = ({ title, siteTitleTemplate, description, image, article, isShishimai }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    siteName,
    titleTemplate,
    defaultDescription,
    shishimaiDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;

  const imagePath = image || defaultImage;

  const seo = {
    siteName: siteName,
    title: title || siteName,
    titleTemplate: title ? (siteTitleTemplate || titleTemplate) : "",
    description: description || (isShishimai ? shishimaiDescription : defaultDescription),
    image: imagePath ? `${siteUrl}${imagePath}` : "",
    url: `${siteUrl}${pathname}`,
  };

  let fullTitle = "";
  if (seo.titleTemplate !== "" && seo.title !== "") {
    fullTitle = seo.titleTemplate.replace(/%s/g, seo.title);
  } else {
    fullTitle = seo.title;
  }

  return (
    <>
      <title>{fullTitle}</title>
      <meta name="description" content={seo.description} />
      {seo.image && <meta name="image" content={seo.image} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? null : true) && <meta property="og:type" content="website" />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.siteName && <meta property="og:site_name" content={seo.siteName} />}
      {fullTitle && <meta property="og:title" content={fullTitle} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary" />
      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
      {fullTitle && <meta name="twitter:title" content={fullTitle} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </>
  );
};

export default Seo;

Seo.propTypes = {
  title: PropTypes.string,
  siteTitleTemplate: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

Seo.defaultProps = {
  title: null,
  siteTitleTemplate: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteName: title
        titleTemplate
        defaultDescription: description
        shishimaiDescription
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`;
