import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";

type ExternalLinkProps = {
  children: string;
  url: string;
  title?: string;
  classic?: boolean;
};

const ExternalLink: React.FC<ExternalLinkProps> = (props) => {
  const children = props.children;
  const url = props.url;
  const title = props.title || url;
  const isClassic = props.classic ? true : false;

  if (!url) {
    return (
      null
    );
  }

  /*
  if (url.indexOf('https://myshishilist.wordpress.com/') === 0 ||
    url.indexOf('https://shishi-photo.jp/') === 0) {
    if (isClassic) {
      return (
        <a className={classicStyles.a} href={url} title={title} target='_blank' rel='noopener'>
          {children}
        </a>
      )
    } else {
      return (
        <a href={url} title={title} target='_blank' rel='noopener'>
          {children}
        </a>
      )
    }
  } else {
  */
  if (isClassic) {
    return (
      <OutboundLink href={url} title={title} target="_blank" rel="noopener">
        {children + " "}
        <img style={{ height: "0.7em", border: "0" }} src="/img/external.png" alt="外部サイト" />
      </OutboundLink>
    );
  } else {
    return (
      <OutboundLink href={url} title={title} target="_blank" rel="noopener">
        {children + " "}
        <img style={{ height: "0.7em", border: "0" }} src="/img/external.png" alt="外部サイト" />
      </OutboundLink>
    );
  }
  /*
  }
  */
};

export default ExternalLink;
