/** @jsxImportSource theme-ui */

import { Link } from "gatsby";
import { BaseStyles } from "theme-ui";
import ExternalLink from "../components/external-link";
import Global from "../components/global";
import { Header } from "../components/header";
import Main from "../components/main";
import Seo from "../components/seo";
import Wrapper from "../components/wrapper";

const TITLE = "Googleカレンダー";

export const Head = () => <Seo title={TITLE} article={true} />;

const Calendar = () => {
  return (
    <>
      <Global />
      <Header />
      <Main>
        <Wrapper>
          <BaseStyles>
            <h1>{TITLE}</h1>
            <div sx={{ marginTop: "2.0rem", marginBottom: "2.0rem" }}>
              今後の予定を順次Googleカレンダーに追加していきます。<br />
              ご自身のGoogleカレンダーに取り込みたい場合は、以下のカレンダーIDを使ってみてください。<br />
              <ul sx={list}>
                <li>カレンダーID: 5nr8s1nod0okd9nubclfqag59s@group.calendar.google.com</li>
                <li>
                  iCal形式:{" "}
                  <ExternalLink url="https://www.google.com/calendar/ical/5nr8s1nod0okd9nubclfqag59s%40group.calendar.google.com/public/basic.ics">
                    https://www.google.com/calendar/ical/5nr8s1nod0okd9nubclfqag59s%40group.calendar.google.com/public/basic.ics
                  </ExternalLink>
                </li>
              </ul>
            </div>
            <iframe
              title="関東地方の郷土芸能発表会など"
              src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=NW5yOHMxbm9kMG9rZDludWJjbGZxYWc1OXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=amEuamFwYW5lc2UjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%234285F4&amp;color=%23D81B60&amp;showTabs=1"
              style={{ padding: "4px", border: "1px solid #808080" }}
              width="800"
              height="660"
              frameBorder="0"
              scrolling="no"
            >
            </iframe>

            <div sx={{ marginTop: "2.5rem", marginBottom: "2.0rem" }}>
              獅子舞大会のカレンダーはこちらです。<br />
              <ul sx={list}>
                <li>カレンダーID: okfi20mogopusvn5fqo5r7bv1s@group.calendar.google.com</li>
                <li>
                  iCal形式:{" "}
                  <ExternalLink url="https://calendar.google.com/calendar/ical/okfi20mogopusvn5fqo5r7bv1s%40group.calendar.google.com/public/basic.ics">
                    https://calendar.google.com/calendar/ical/okfi20mogopusvn5fqo5r7bv1s%40group.calendar.google.com/public/basic.ics
                  </ExternalLink>
                </li>
              </ul>
            </div>
            <iframe
              title="獅子舞大会などの日程"
              src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=b2tmaTIwbW9nb3B1c3ZuNWZxbzVyN2J2MXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=amEuamFwYW5lc2UjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%234285F4&amp;color=%23D81B60&amp;showTabs=1"
              style={{ padding: "4px", border: "1px solid #808080" }}
              width="800"
              height="600"
              frameBorder="0"
              scrolling="no"
            >
            </iframe>
            <div sx={{ marginTop: "2.0rem" }}>
              <Link to="/">トップページに戻る</Link>
            </div>
          </BaseStyles>
        </Wrapper>
      </Main>
    </>
  );
};

export default Calendar;

const list = {
  listStyleType: "disc",
  marginTop: "0.5rem",

  "li": {
    marginLeft: "1.5rem",
    marginTop: "0.3rem",
  },
};
