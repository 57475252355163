/** @jsxImportSource theme-ui */

import { graphql, Link, useStaticQuery } from "gatsby";
import { ThemeUICSSObject } from "theme-ui";
import { Nav } from "./nav";
import { theme_color_header_background, theme_header_height } from "./theme-constants";
// gatsby-plugin-theme-ui/index.js cannot be changed to TypeScript file. This is a workaround to avoid
// TypeScript error: "'src/gatsby-plugin-theme-ui/index.js' implicitly has an 'any' type.ts(7016)"
const theme = require("../gatsby-plugin-theme-ui");

type HeaderProps = {
  siteTitle?: string;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const siteTitle = props.siteTitle;
  const { site } = useStaticQuery(graphql`
    query Header {
      site {
        siteMetadata {
          siteName: title
        }
      }
    }
  `);

  return (
    <header sx={headerStyle}>
      <div sx={headerWrapperStyle}>
        <h1 sx={siteTitleStyle}>
          <Link to="/" sx={siteTitleLinkStyle}>
            {siteTitle || site.siteMetadata.siteName}
          </Link>
        </h1>
        <Nav />
      </div>
    </header>
  );
};

const headerStyle: ThemeUICSSObject = {
  position: "absolute",
  zIndex: 100,
  width: "100%",
  height: theme_header_height,
  top: 0,
  backgroundColor: theme_color_header_background,
  color: theme.default.colors.text,
  borderBottom: "1px solid #DBDDDF",
  boxShadow: "0 3px 8px 0 #755C5C40",
};

const headerWrapperStyle: ThemeUICSSObject = {
  display: "flex",
  alignSelf: "stretch",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",

  // this is required for menu width to be 90%
  position: "relative",

  /* copied from wrapper.js */
  width: "90%",
  maxWidth: 1024,
  margin: "0 auto",
  padding: "0 1rem",

  "@media (min-width: 800px)": {
    padding: "0 2rem",
  },

  "@media (min-width: 1600px)": {
    maxWidth: 1024,
  },

  "@media (min-width: 2200px)": {
    maxWidth: 1280,
  },
};

const siteTitleStyle = {
  margin: 0,

  "@media screen and (min-width: 800px)": {
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
  "@media screen and (max-width: 800px)": {
    fontSize: "1.0rem",
  },
  "@media (max-width: 360px)": {
    fontSize: "0.9rem",
  },
};

const siteTitleLinkStyle: ThemeUICSSObject = {
  position: "relative",
  lineHeight: "3rem",
  color: "inherit !important",
  textDecoration: "none",
  margin: 0,
  zIndex: 1,
};
