/** @jsxImportSource theme-ui */

import { graphql, Link, useStaticQuery } from "gatsby";
import { useState } from "react";
import { ThemeUICSSObject } from "theme-ui";
import { theme_color_header_background, theme_easing, theme_header_height } from "./theme-constants";

const menuItems = [
  { label: "ホーム", link: "/" },
  { label: "獅子舞大会", link: "/shishimai/" },
  { label: "カレンダー", link: "/calendar/" },
  { label: "リンク", link: "/links/" },
];

const navToggleClosedText = "▼ メニュー";
const navToggleOpenText = "▲ メニュー";

export const Nav: React.FC = () => {
  const [navOpen, setNavOpen] = useState(false);
  function toggleNavOpen() {
    setNavOpen(!navOpen);
  }

  return (
    <>
      <Navbar isOpen={navOpen}>
        {menuItems.map((item) => (
          <li sx={navItemStyle} key={item.label}>
            <Link
              to={item.link}
              onClick={toggleNavOpen}
              activeClassName="active"
              partiallyActive={item.link !== "/"}
              sx={navLinkStyle}
            >
              <span>{item.label}</span>
            </Link>
          </li>
        ))}
      </Navbar>
      <NavToggle isOpen={navOpen} onClick={toggleNavOpen} />
    </>
  );
};

type NavbarProps = {
  children: React.ReactNode;
  isOpen: boolean;
};

const Navbar: React.FC<NavbarProps> = (props) => {
  const children = props.children;
  const isOpen = props.isOpen;

  return (
    <ul sx={navBarStyle(isOpen)}>
      {children}
    </ul>
  );
};

type NavToggleProps = {
  onClick?: () => void;
  isOpen: boolean;
};

const NavToggle: React.FC<NavToggleProps> = (props) => {
  const onClick = props.onClick;
  const isOpen = props.isOpen;

  return (
    <button onClick={onClick} sx={navToggleStyle(isOpen)}>
      <span className="closed">{navToggleClosedText}</span>
      <span className="open">{navToggleOpenText}</span>
    </button>
  );
};

function navBarStyle(isOpen: boolean): ThemeUICSSObject {
  return {
    color: "inherit",

    "@media (max-width: 800px)": {
      position: "absolute",
      bottom: 0,
      left: 0,
      transform: "translate3d(0, 100%, 0)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      opacity: isOpen ? 1 : 0,
      zIndex: 1000,
      backgroundColor: theme_color_header_background,
      boxShadow: "0 1rem 2rem -0.5rem rgba(85, 71, 64, 0.5)", // transparentize(0.5, theme.colors.text)
      transition: `all 150ms ${theme_easing}`,
      pointerEvents: isOpen ? "all" : "none",
    },

    "@media (min-width: 800px)": {
      display: "flex",
      flexDirection: "row",
      alignSelf: "stretch",
      alignItems: "stretch",
      justifyContent: "flex-end",
      flex: "1 0 auto",
      margin: 0,
      opacity: 1,
      pointerEvents: "all",
    },
  };
}

const navItemStyle = {
  flex: "0 0 auto",
  display: "flex",
  alignItems: "stretch",
  color: "inherit",

  "@media (max-width: 800px)": {
    "&:not(:last-child)": {
      borderBottom: "1px solid rgba(85, 71, 64, 0.2)", // transparentize(0.8, theme.colors.text)
    },
  },
};

const navLinkStyle: ThemeUICSSObject = {
  flex: "1 0 auto",
  lineHeight: theme_header_height,
  padding: "0 0.75rem",
  display: "flex",
  alignItems: "center",
  position: "relative",
  textAlign: "center",
  textTransform: "uppercase",
  fontSize: "0.8rem",
  letterSpacing: "0.5px",
  textDecoration: "none",
  color: "inherit !important",
  opacity: 0.5,
  overflow: "visible",
  transition: `all 150ms ${theme_easing}`,
  zIndex: 1,

  "span": {
    display: "block",
    width: "100%",
  },

  "&:after": {
    content: '""',
    display: "none",
    position: "absolute",
    top: "-6px",
    left: 0,
    width: "100%",
    height: "6px",
    backgroundColor: "rgba(255, 255, 255, 0.15)", // transparentize(0.85, theme.colors.background)
    transform: "translate3d(0, -100%, 0)",
    transition: `all 150ms ${theme_easing}`,
  },

  "&:before": {
    content: '""',
    position: "absolute",
    display: "none",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(to bottom, rgba(102, 89, 83, 0.25), transparent 1.5rem)",
    opacity: 0,
    zIndex: -1,
    transform: "translate3d(0, -100%, 0)",
    transition: `all 150ms ${theme_easing}`,
  },

  "&:focus-visible": {
    opacity: 1,
    "&:before": {
      transform: "translate3d(0, 0, 0)",
      opacity: 0.5,
    },
  },

  "&:hover:not(.active)": {
    opacity: 1,
    "&:before": {
      transform: "translate3d(0, 0, 0)",
      opacity: 1,
    },
    "&:after": {
      backgroundColor: "rgba(85, 71, 64, 0.2)", // transparentize(0.8, theme.colors.text)
    },
  },

  "&:hover": {
    opacity: 1,
    "&:before": {
      transform: "translate3d(0, 0, 0)",
      opacity: 1,
    },
    "&:after": {
      transform: "translate3d(0, 0, 0)",
    },
  },

  "&:active": {
    opacity: 1,
    "&:before": {
      transform: "translate3d(0, 0, 0)",
      opacity: 1,
    },
    "&:after": {
      transform: "translate3d(0, 0, 0)",
    },
  },

  "&.active": {
    opacity: 1,
    "&:before": {
      transform: "translate3d(0, 0, 0)",
      opacity: 1,
      background: "linear-gradient(to bottom, rgba(127, 117, 111, 0.25), transparent 1.5rem)",
    },
    "&:after": {
      transform: "translate3d(0, 0, 0)",
    },
  },

  "@media (min-width: 800px)": {
    lineHeight: 1,
    "&:after": {
      display: "block",
    },
    "&:before": {
      display: "block",
    },
  },
};

function navToggleStyle(isOpen: boolean): ThemeUICSSObject {
  return {
    position: "relative",
    padding: 0,
    border: 0,
    background: "transparent",
    color: "inherit",
    cursor: "pointer",
    marginLeft: "1rem",
    fontSize: "0.8rem",
    lineHeight: 1,
    alignSelf: "stretch",
    textTransform: "uppercase",
    opacity: 0.5,
    overflow: "visible",
    transition: `all 150ms ${theme_easing}`,

    ".open": {
      display: isOpen ? "block" : "none",
    },
    ".closed": {
      display: isOpen ? "none" : "block",
    },

    "&:focus": {
      opacity: 1,
      textDecoration: "underline",
    },
    "&:hover": {
      opacity: 1,
    },

    "@media (min-width: 800px)": {
      display: "none",
    },

    "@media (max-width: 360px)": {
      fontSize: "0.7rem",
    },
  };
}
