/** @jsxImportSource theme-ui */

type WrapperProps = {
  children: React.ReactNode;
  classic?: boolean;
};

const Wrapper: React.FC<WrapperProps> = (props) => {
  const children = props.children;
  const classic = props.classic ?? false;

  if (!classic) {
    return (
      <div
        sx={{
          width: "90%",
          maxWidth: 1024,
          margin: "0 auto",
          padding: "0 1rem",

          "@media (min-width: 800px)": {
            padding: "0 2rem",
          },

          "@media (min-width: 1600px)": {
            maxWidth: 1024,
          },

          "@media (min-width: 2200px)": {
            maxWidth: 1280,
          },
        }}
      >
        {children}
      </div>
    );
  } else {
    return (
      <div
        sx={{
          width: "90%",
          maxWidth: 1920,
          margin: "0 auto",
          padding: "0 1rem",

          "@media (min-width: 800px)": {
            padding: "0 2rem",
          },

          "@media (min-width: 1600px)": {
            maxWidth: 1920,
          },

          "@media (min-width: 2200px)": {
            maxWidth: 1920,
          },

          p: {
            margin: "0 0 1.0rem 0",
          },
          // this should be in sync with theme's base style
          a: {
            color: "link",
            textDecorationColor: "rgba(178, 0, 0, 0.3)", // transparentize(0.7, link-color),
            transition: "all 150ms cubic-bezier(0.215, 0.610, 0.355, 1.000)",

            "&:visited": {
              color: "visitedLink",
              textDecorationColor: "rgba(85, 26, 139, 0.3)", // transparentize(0.7, visited-link-color)
              transition: "all 150ms cubic-bezier(0.215, 0.610, 0.355, 1.000)",
            },

            "&:visited:hover": {
              color: "#4D177D", // shade(0.1, visited-link-color)
              textDecorationColor: "rgba(85, 26, 139, 0.5)", // transparentize(0.5, visited-link-color)
            },

            "&:visited:focus": {
              color: "#4D177D", // shade(0.1, visited-link-color)
              textDecorationColor: "#4D177D", // shade(0.1, visited-link-color)
            },

            "&:hover": {
              color: "A00000", // shade(0.1, link-text-color)
              textDecorationColor: "rgba(178, 0, 0, 0.5)", // transparentize(0.5, link-text-color)
            },

            "&:focus": {
              color: "A00000", // shade(0.1, link-text-color)
              textDecorationColor: "A00000", // shade(0.1, link-text-color)
            },
          },
        }}
      >
        {children}
      </div>
    );
  }
};

export default Wrapper;
