/** @jsxImportSource theme-ui */

type MainProps = {
  children: React.ReactNode;
};

const Main: React.FC<MainProps> = (props) => {
  const children = props.children;

  return (
    <main sx={mainStyle}>
      {children}
    </main>
  );
};

export default Main;

const mainStyle = {
  marginTop: "4.0rem",
  marginBottom: "1.0rem",
};
